.stats-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  margin-top: 20px;
}
.stats-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  text-align: center;
}
.stats-card img {
  height: 5vw;
  width: auto;
  border-radius: 3px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
.stats-card img:hover {
  -webkit-filter: brightness(50%);
}
.stats-body {
  margin-left: 3vw;
  margin-right: 0vh;
  width: 300px;
}
.stats-body-mobile {
  display: flex;
  flex-direction: row;
}
.row_stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.row_stats p {
  width: 200px;
  margin-bottom: 7px;
}
.row_stats span {
  margin-left: 0px;
}

/* .stats-body-mobile {
  display: flex;
  align-items: center;
} */
@media (max-width: 760px) {
  .chart-stat {
    display: none;
  }
  .stats-card {
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .stats-head h2 {
    font-size: 2vh;
    text-align: center;
  }
  .stats-body p {
    font-size: 100%;
    width: 55vw;
  }
  .stats-body-mobile {
    display: flex;
    flex-direction: column;
  }
  .stats-body {
    width: 50vw;
    margin-right: 0;
  }
  .stats-card img {
    height: 20vw;
  }
}
