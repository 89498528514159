
.admin{
    display: flex;
    align-items: center;
    justify-content: center;

}
.form-admin{
    width: 60%;
    display: flex;
    flex-direction: column;

}
.form-group{
    display: flex;
    flex-direction: column;
    margin-top:4%;
}
.form-group input{
    height:5vh;
    border-radius:5px;
    border: 1px solid #071358;
    font-weight:bold;
    text-align:center;
}
.form-select{
    color:#071358;
    font-weight:bold;
    height:5vh;
    border-radius:5px;
    text-align:center;
    
    
}
.form-select select{
    border: 1px solid #071358;
}

.btn-primary{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:3%;
    height:5vh;
    width:50%;
    background-color: #071358;
    border:none;
    color: #fff;
    border-radius:5px;
}
.btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

