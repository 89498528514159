@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  /* background-color: antiquewhite; */
}

/* css per della pagina caricagratta */
.caricagratta {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: -45px;
}
.list-gratta-caricati {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  width: 90vw;
  margin-left: 45px;
  /* margin-left: 8vw; */
}

.caricagratta form {
  width: 90vw;
  height: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caricagratta form input {
  height: 5.4vw;
  /* width: 70vw; */
  height: 100%;
  font-size: 1.5vw;
  text-align: center;
  border: 2px solid;
  border-radius: 4px;
  border-color: #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #071358;
}
.container-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: -webkit-fill-available;
  /* margin-right: 9%; */
}

.search-cg {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
  width: 70vw;
  height: 4.5vw;
  margin-left: 15vw;
}

.search-cg input {
  height: 60px;
  text-indent: 25px;
  border: 5px solid #d6d4d4;
}

.search-cg .icon-bar-cg {
  position: absolute;
  top: 0.6vw;
  left: 1vw;
  color: #071358;
  font-size: 3.5vw;
}
#icon-brc-cg {
  top: 1.2vw;
  left: 5vw !important;
  font-size: 2.5vw;
}
/* css per il btn della pagina caricagratta */

.form-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.8vw;
}
.btn-group-ms {
  display: flex;
  align-items: center;
  justify-content: center;
}
.head-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.5%;
  margin-right: 10%;
}
.btn-mag {
  color: white;
  height: 4.3vw;
  width: 8.3vw;
  margin-right: 1%;
  background-color: #071358;
  border-color: #071358;
  border-radius: 15px;
  border: 3px solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
}
.btn-mag:hover {
  background-color: #fd8c22;
  border: 3px solid #fd8c22;
}

.btn-mag.active {
  color: white;
  background-color: #fd8c22;
  font-size: 1.3vw;
  border: 3px solid #fd8c22;
}
.btn-mag:active {
  height: 3.5vw;
  width: 8vw;
  font-size: 1.5vw;
}

.btn-mag-carica {
  color: white;
  height: 8vh;
  width: 22vh;
  margin-left: 3vh;
  background-color: #071358;
  border-color: #071358;
  border-radius: 15px;
  border: 3px solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 3vh;
}
.btn-mag-carica:hover {
  background-color: #fd8c22;
  border: 2.8px solid #fd8c22;
}

.btn-mag-carica:active {
  color: #071358;
  background-color: #fd8c22;
  font-size: 1.8vh;
  border: 2.8px solid #fd8c22;
  height: 7vh;
  width: 21vh;
  transition: none;
}

.load-succes {
  /* color: #071358; */
  /* background-color: white; */
  border: 1px solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 2vw !important;
  border-radius: 5px;
  height: 12vw;
  /* width: vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5vw;
  flex-direction: row;
  margin: 0.5vh;
}
.load-succes img {
  margin-right: 0.5vh;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  border-radius: 10px !important;
  /* margin-top: 0.5vw !important; */
  height: 6vw;
  object-fit: contain;
  /* width: 10px; */
}
.icon-check {
  color: green;
  font-size: 2.5vh;
}
.mobile-viewer {
  display: none;
}

.btn_primary {
  color: white;
  height: 40px;
  width: 100%;
  background-color: #071358 !important;
  border-radius: 3px;
  cursor: pointer;
  transition: ease background-color 250ms;
}
/* .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: #071358 !important;
}
.css-2dum1v-MuiDrawer-docked .MuiDrawer-paper {
  background-color: #071358 !important;
} */
.MuiDrawer-docked {
  background-color: #071358 !important;
}
.MuiDivider-root {
  background-color: #ffff !important;
}

/* 

.css-9mgopn-MuiDivider-root {
  background-color: #ffffff !important;
} */
/* .css-uxa1ks-MuiListItem-root:hover {
  background-color: #fd8c22 !important;
} */

@media (max-width: 767px) {
  .container-form {
    display: none;
  }
  .mobile-viewer {
    display: flex;
    margin-left: 50px;
    margin-top: 50px;
  }
}
