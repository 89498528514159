.statistiche {
  margin: 0.5vw;
  display: flex;
  flex-direction: column;
  margin-left: 5.5%;
}
.single-header {
  display: flex;
  flex-direction: column;
}
.data-pikers-statistiche {
  display: flex;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  font-size: 5vw;
  align-items: flex-end;
  margin-left: 77.5vw;
  position: absolute !important;
  margin-top: -2.9vw;
}
.data-calendar-stats {
  margin-top: 3.4vw;
  z-index: 3;
}
.btn-date-stats {
  color: white;
  height: 3.2vw;
  width: 17vw;
  margin-top: 2.6vw;
  background-color: #fd8c22;
  border-color: #fd8c22;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 1.5vw;
}
.btn-date-stats:hover {
  background-color: #071358;
  border: 3px solid #071358;
}
.head-scelta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.btn-head {
  width: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #071358;
  margin-top: -0.5vw;
  transition: ease background-color 250ms;
  color: white;
  background-color: #fd8c22;
  height: 3vw;
  padding: 0.5vw;
  cursor: pointer;
  margin-left: 2vw;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 1.3vw;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-head:hover {
  background-color: #071358;
}
.result-render {
  overflow-y: auto;
  height: 90%;
}
.statistiche-header {
  display: flex;
  justify-content: flex-start;
}
.tempo-statistiche {
  display: flex;
  flex-direction: row;
  width: 15vw;
  margin-left: 3vw;
  border-top: 1px solid #fd8c22;
  background-color: #071358;
  margin-right: 3vw;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  align-items: center;
}
.tempo-statistiche span {
  width: 30vw;
  color: white;
  text-align: center;
  font-weight: bold;
}
.link-stampa {
  text-decoration: none;
}
.ico-pdf {
  margin-top: 0.5vw;
  margin-left: -3vw;
}
.ico-pdf-giacienze {
  margin-top: 0.5vw;
  margin-left: 0.5vw;
}
.link-pdf {
  text-decoration: none;
  color: #071358;
}
.link-pdf span {
  color: #071358;
  font-size: 2vw;
}
.stampa-vendite {
  color: white;
  background-color: #071358;
  height: 2.5vw;
  width: 17vw;
  padding: 0.5vw;
  cursor: pointer;
  margin-left: 2vw;
  border-radius: 10px;
  font-size: 1.3vw;
  text-align: center;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  text-align: center;
  justify-content: center;
}
.stampa-vendite:hover {
  background-color: #fd8c22;
}
.stat-general {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: 1vw;
  margin-bottom: 1vw;
}
.stat-general p {
  font-size: 1.2vw;
  margin-right: 2vw;
}
.input_date {
  width: 150px;
  height: 33px;
  /* margin-left: 10px; */
  border-radius: 3px;
  border: 1px solid #071358;
  font-size: 18px;
  text-align: center;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  font-size: 35px;
  color: white;
}
.row_date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.row_date p {
  font-size: 18px;
  color: #071358;
  margin-bottom: 0;
  margin-right: 5px;
}
@media (max-width: 767px) {
  .statistiche {
    margin-left: 50px;
  }
  .statistiche-header {
    flex-direction: column;
  }
  .head-scelta {
    margin-left: 5vh;
  }
  .btn-head {
    height: 10vw;
    width: 25vw;
    font-size: 1.5vh;
    margin-top: 2vw;
  }
  .data-pikers-statistiche {
    margin-left: 50vw;
    margin-top: 7vh;
  }
  .data-calendar-stats {
    margin-top: 7vh;
    margin-left: -35vw;
  }
  .btn-date-stats {
    width: 25vw;
    height: 5vh;
    font-size: 1.5vh;
  }
  .stampa-container {
    margin-top: 3.5vw;
    height: 10vw;
    margin-left: 8.4vw;
  }
  .stampa-vendite {
    font-size: 1.6vh;
    height: 10vw;
  }
  .stampa-vendite svg {
    display: none;
  }
  .tempo-statistiche {
    width: 30vw;
    font-size: 1.5vh;
    margin-top: -6vh;
    margin-left: 9vw;
    height: 9vw;
  }
  .ico-pdf {
    display: none;
  }
  .ico-pdf-giacienze {
    display: none;
  }
  .graf-stat {
    display: none;
  }
  .stat-general {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 1vw;
    margin-bottom: 1vw;
  }
  .stat-general p {
    font-size: 5vw;
    margin-right: 2vw;
  }
}
