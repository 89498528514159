.gratta-card {
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
  text-align: center;
}
.gratta-card img {
  height: 10vw;
  width: auto;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
.gratta-card img:hover {
  -webkit-filter: brightness(50%);
}
.gratta-card h3 {
  color: #071358;
  font-size: 1vw;
}

/* .gratta-card button {
  height: 3vh;
  color: white;
  background-color: #fd8c22;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 2px solid #071358;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
}
.gratta-card button:hover {
  -webkit-filter: grayscale(0%);
} */

@media (max-width: 767px) {
  .gratta-card img {
    height: 25vw;
    width: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
  }
  .gratta-card h3 {
    color: #071358;
    font-size: 2vw;
  }
  .gratta-card {
    display: flex;
    flex-direction: column;
    margin-left: 3vw;
    text-align: center;
  }
}
