.home {
  margin-left: 5.5%;
  /* width: 86vw; */
  /* width: 94%; */
  /* background-color: #071358; */
}
.data-pikers {
  display: flex;
  justify-content: flex-end;
  font-size: 10vh;
  align-items: flex-end;
  margin-left: 72vw;
  margin-right: 2.2%;
  position: absolute !important;
  /* position: relative !important; */
}
.data-calendar {
  position: absolute;
  margin-left: 2vw;
}

.icon-calendar {
  font-size: 2.5vw;
  margin-left: 3vw;
  color: white;
}
.icon-keyboard {
  font-size: 4vw;
  margin-top: 3vw;
  margin-left: 3vw;
  color: #071358;
}
.icon-keyboard:active {
  font-size: 4.2vw;
  margin-top: 3vw;
  margin-left: 3vw;
  color: #071358;
}
.icon-keyboard-active {
  font-size: 4.2vw;
  margin-top: 3vw;
  margin-left: 3vw;
  color: #1b34bd;
}
.icon-keyboard-active:active {
  font-size: 4vw;
  margin-top: 3vw;
  margin-left: 3vw;
  color: #1b34bd;
}

.icon-keyboard:hover {
  color: #1b34bd;
}
.data-pikers-date {
  height: 8vh;
  width: 20vw;
  font-size: 4.5vh;
  cursor: pointer;
  position: relative !important;
}

.btn-date {
  margin-left: 0.8vw;
  color: white;
  height: 4.5vw;
  width: 20vw;
  margin-top: 2.7vw;
  background-color: #fd8c22;
  border-color: #fd8c22;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 2vw;
}
.btn-date:hover {
  background-color: #071358;
  border: 3px solid #071358;
}
.form-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 72.3%;
  /* background-color: #fd8c22; */
}

.form-container form {
  width: 100%;
  height: 4vw;
  margin-top: 3vw;
}

.input-e-data-pikers {
  display: flex;
  justify-content: space-between;
  /* margin-right: -2vw; */
  align-items: center;
}
.input-e-data-pikers form input {
  height: 4vw;
  width: 100%;
  font-size: 120%;
  text-align: center !important;
  border: 3px solid !important;
  /* border-radius: 4px; */
  border-color: #fd8c22 !important;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  color: #071358 !important;
}
.input-e-data-pikers form span {
  color: white !important;
  background-color: #fd8c22 !important;
}
.input-e-data-pikers form input:active {
  border-color: #071358 !important;
}
.input-e-data-pikers form input:focus {
  border-color: #071358 !important;
  outline: none !important;
  border: 3px solid #071358 !important;
}

.search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.search input {
  height: 60px;
  text-indent: 25px;
  border: 2px solid #d6d4d4;
}

.search .icon-bar {
  position: absolute;
  top: 0.2vw;
  left: 1vw;
  color: #071358;
  font-size: 3.5vw;
}
#icon-brc {
  top: 0.7vw;
  left: 5vw !important;
  font-size: 2.5vw;
}

.form-carrello {
  margin-top: 1.5vw;
  display: flex;
  height: 35vw;
  width: 94vw;
}
.table-container {
  display: flex;
  flex-direction: column;
  border-left: 5px solid #071358;
  border-right: 5px solid #071358;
  border-bottom: 5px solid #071358;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  height: 93%;
  background-color: white;
}
.head-carello {
  display: flex;
  font-size: 0.7vw;
  justify-content: flex-start;
  padding-left: 1vw;
  padding-right: 1vw;
  background-color: #071358;
  padding: 1%;
  color: white;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border-bottom: 0.3vh solid #fd8c22;
}
.form-carrello form {
  width: 80%;
  display: flex;
  flex-direction: column;

  /* border: 0.5vh solid #fd8c22; */
  border-radius: 10px;
}
.single-product {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 3vw;
  background-color: white;
}

.icon-delete {
  font-size: 2.3vw;
  color: red;
  cursor: pointer;
}
.icon-delete:hover {
  -webkit-filter: brightness(50%);
}
.icon-delete:active {
  font-size: 2.4vw;
}
.container-totali {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 3vw;
  align-items: center;
  height: 100%;
}

.tot-car-container {
  margin-top: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.storno-container {
  margin-top: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.storno-container button {
  color: white;
  height: 4.5vw;
  width: 20vw;
  background-color: #071358;
  border-color: #071358;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  /* margin-top: -10vh; */
  border: 3px solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 1.8vw;
}
.storno-container button:hover {
  background-color: #fd8c22;
}
.storno-container span {
  margin-top: 6px;
  color: white;
}
.scarica-mazzetta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scarica-mazzetta-container button {
  color: white;
  height: 4.5vw;
  width: 20vw;
  margin-top: 1vw;
  background-color: #071358;
  border-color: #fd8c22;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 1.8vw;
}
.scarica-mazzetta-container button:hover {
  background-color: #fd8c22;
}
.scarica-mazzetta-container span {
  margin-top: 6px;
  color: white;
  font-size: 2vw;
}
.titolo-carrello {
  display: flex;
  align-items: center;
  background-color: #071358;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 20vw;
  color: white;
  justify-content: space-between;
  margin-left: 1vw;
  margin-right: 1vw;
  padding: 3%;
  border-bottom: 1vw solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: -2.3vw;
}
.btn-carrello {
  color: white;
  height: 3.5vw;
  width: 20vw;
  background-color: #fd8c22;
  border-color: #fd8c22;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 3px solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 2vw;
}

.btn-carrello:hover {
  background-color: #071358;
  border: 2.8px solid #071358;
}
.btn-carrello:active {
  color: #071358;
  background-color: #071358;
  font-size: 1.8vh;
  border: 2.8px solid #071358;

  transition: none;
}
.tot-giorno-container {
  display: flex;
  align-items: center;
  background-color: #071358;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  justify-content: space-between;
  margin-left: 1vw;
  margin-right: 1vw;
  padding: 3%;
  border-bottom: 1vw solid #fd8c22;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 20vw;
  /* margin-bottom: -2.6vh; */
  margin-top: 1vw;
  height: 5.5vw;
}
.tot-car-container h1::after {
  color: green;
}
.tot-giorno-container h1 {
  font-size: 2vw;
}
.tot-giorno-container p {
  font-size: 1.25vw;
}
.tot-car-container h1 {
  font-size: 2vw;
}
.tot-car-container p {
  font-size: 1.25vw;
}

.home-table {
  color: #071358;
  width: 100%;
  text-align: center;
  border-radius: 10px;
}

.home-table thead {
  /* background-color: #071358; */
  color: white;
  border-radius: 10px;
  font-size: 2vh;
  padding-bottom: 1vw;
  width: 100%;
}
.home-table tr {
  /* padding-left: 1vw;
  padding-right: 2vw; */
  margin-bottom: 2vw;
  margin-top: 0.2vh;

  border-radius: 10px;
}
.home-table tbody {
  margin-top: 1vw;
  font-size: 1.7vw;
}

.body-table {
  display: flex;
  justify-content: flex-start;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 0.5vw;
  color: #071358;
  font-size: 0.85vw;
}
.body-table img {
  height: 2vw;
  margin-right: 1vw;
  border-radius: 2px;
}

.hd-nome {
  display: flex;
  text-align: start;
  align-items: center;
  width: 17vw;
}
.t-nome {
  text-align: center;
  width: 17vw;
  margin-left: -1vw;
}
.hd-serieM {
  display: flex;
  text-align: start;
  align-items: center;
  width: 7vw;
}
.t-serie {
  text-align: center;
  width: 7vw;
}
.hd-progM {
  display: flex;
  text-align: start;
  align-items: center;
  width: 10vw;
}
.t-prog {
  width: 10vw;
  margin-left: 1.2vw;
}

.hd-vUnita {
  display: flex;
  text-align: start;
  align-items: center;
  width: 12.6vw;
}
.t-prezzo {
  width: 12.6vw;
  margin-left: -0.8vw;
}

.hd-giacenza {
  display: flex;
  text-align: start;
  align-items: center;
  width: 12.6vw;
}
.t-giacenza {
  width: 12.95vw;
  margin-left: 1vw;
}
.hd-giacenza-anime {
  display: flex;
  text-align: start;
  align-items: center;
  width: 12.6vw;
  animation: new 0.5s linear infinite;
  color: red;
  font-weight: bold;
  font-size: 1.5vw;
}

/* switch  */
.sw-es {
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: absolute;
  top: 4%;
  left: 60%;
  width: 74px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 11 */
#button-11 {
  overflow: visible;
}

#button-11 .knobs {
  perspective: 70px;
}

#button-11 .knobs:before,
#button-11 .knobs:after,
#button-11 .knobs span {
  position: absolute;
  top: 4px;
  border-radius: 2px;
}

#button-11 .knobs:before,
#button-11 .knobs:after {
  width: 20px;
  height: 10px;
  color: #4e4e4e;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
}

#button-11 .knobs:before {
  content: "YES";
  left: 4px;
}

#button-11 .knobs:after {
  content: "NO";
  right: 4px;
}

#button-11 .knobs span {
  right: 4px;
  width: 33px;
  height: 28px;
  background-color: #5cde10;
  transform: rotateY(0);
  transform-origin: 0% 50%;
  transition: 0.6s ease all;
  z-index: 1;
}

#button-11 .checkbox:checked + .knobs span {
  transform: rotateY(-180deg);
  background-color: #f44336;
}

#button-11 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

/* end switch */
@keyframes new {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.95);
  }
  100% {
    transform: scaleX(1);
  }
}
.vendita-result {
  overflow-y: auto;
  height: 90%;
}

/* mobile view */
@media (max-width: 767px) {
  .table-container {
    display: none;
  }

  .btn-date {
    height: 10vw;
    font-size: 35%;
  }
  .icon-calendar {
    font-size: 96%;
  }
  .input-e-data-pikers .form-container {
    display: none;
  }
  .input-e-data-pikers .data-pikers {
    margin-top: 16vh;
    width: 7vw;
  }
  .input-e-data-pikers .data-pikers {
    width: 70%;
    margin-left: 13vw;
  }
  .input-e-data-pikers .data-pikers .btn-date {
    width: 100%;
  }
  .form-carrello form {
    display: none;
  }
  .container-totali {
    margin-top: 10vh;
    margin-left: 30vw;
  }
  .container-totali .tot-car-container {
    width: 100%;
  }
  .tot-giorno-container {
    width: 70vw;
    margin-left: -17vw;
    margin-top: 10vh;
    height: 100%;
  }
  .storno-container {
    display: none;
  }
  .titolo-carrello {
    display: none;
  }
  .btn-carrello {
    display: none;
  }
  .scarica-mazzetta-container {
    display: none;
  }
  .data-calendar {
    margin-top: 2vh;
    margin-left: -60vw;
  }
  .span-text {
    display: none;
  }
  .tot-giorno-container h1 {
    font-size: 10vw;
  }
  .tot-giorno-container p {
    font-size: 1.25vw;
  }
  .tot-car-container h1 {
    font-size: 8vw;
  }
  .tot-car-container p {
    font-size: 4vw;
  }
}
