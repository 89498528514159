.plan-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px !important;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important; */
}
.title-pc {
  /* border: 2px solid lightgray; */
  width: 100%;
  height: 15%;
  font-size: 200%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(57, 57, 57);
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 2%;
}
.plan-card img {
  height: 17%;
  margin-bottom: 2%;
}
.price-pc {
  font-size: 150%;
  margin-bottom: 2%;
  font-weight: bold;
  color: rgb(57, 57, 57);
}
.head-pc {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}
.description-pc {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 120%;
  width: 70%;
  color: rgb(45, 45, 45);
  padding: 2%;
}
.specific-pc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 120%;
  width: 90%;
  margin-left: 15%;
  margin-bottom: 5%;
  padding: 2%;
}
.btn-pc {
  /* margin-top: 2%;
  margin-bottom: 2%; */
  width: 50%;
  height: 4vw;
  /* border: none; */
  /* color: white; */
  /* font-size: 130%; */
  border-radius: 20px !important;
  /* background: #4343e7 !important; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.btn-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}
.row-spec {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 3%;
  color: rgb(68, 68, 68);
  font-size: 90%;
}
.sconto-pc {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
  color: rgb(80, 79, 79);
}

.risparmio-pc {
  color: red;
  margin-bottom: 1%;
}
.img-fm {
  width: 50% !important;
}

@media (max-width: 767px) {
  .plan-card {
    width: 96%;
    height: 92vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1%;
    margin-bottom: 3%;
    border: 2px solid lightgray;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff;
  }
  .btn-link {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-decoration: none */
    margin: 3%;
  }
  .btn-pc {
    margin-top: 2%;
    margin-bottom: 2%;
    width: 50%;
    height: 100%;
    border: none;
    color: white;
    font-size: 130%;
    border-radius: 25px;
    background: #4343e7;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
