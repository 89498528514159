.stampa-vendite-container {
  margin-top: 1.4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stampa-vendite-container form {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stampa-vendite-container form span {
  font-size: 2vw;
  color: #071358;
  margin-top: 0.5vw;
}
.stampa-vendite-container form input {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  border: 3px solid #fd8c22;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70vw;
  height: 2.5vw;
}
.stampa {
  overflow-y: auto;
  height: 90%;
}
.result-stampa {
  display: flex;
  flex-direction: row;
  margin-top: 1vw;
  align-items: center;
  justify-content: center;
  margin-left: 10vw;
}
.col-res {
  width: 20vw;
}

.result-stampa .col-res span {
  font-size: 1vw;
  color: #071358;
}
.icon-stampa {
  font-size: 2vw;
  margin-left: 1vw;
}

@media (max-width: 760px) {
  .stampa-vendite-container {
    margin-left: 50px;
  }
  .stampa-vendite-container form{
    height: 10vw;
  }
  .stampa-vendite-container form input{
    height: 10vw
  }
  .stampa-vendite-container form span {
    font-size: 7vw
  }
  .result-stampa {
    width: 90vw;
    height: 8vw
  }
  .col-res {
    width: 35vw;
    height: 7vw;
  }
 .result-stampa .col-res span{
    font-size:3vw;
  }
}
