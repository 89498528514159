.navbar-mio {
  background-color: white;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: -1.2vh; */
}

.navbar-container {
  display: flex;
  align-items: center;
}

.menu-bars {
  height: 50px;
  font-size: 150%;
  background: none;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-decoration: none !important;
  background-color: #071358;
  margin-right: 0;
}
.menu-bars:hover {
  background-color: #fd8c22;
  width: 100%;
}
.menu-bars p {
  margin-right: 0.5vh;
  font-size: 80%;
  color: white;
  /* font-weight: bold; */
  margin-left: 1.5vw;
}
.menu-bars span {
  margin-right: 1.5vw;
}

.nav-menu {
  background-color: #071358;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  /* left: -100%; */
  left: -210px;
  transition: 850ms;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-text-mini {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  margin-right: -7.5px;
}

.nav-text-mini a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
.nav-text-mini span {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
.nav-text-mini a:hover {
  background-color: #1a83ff;
}
.nav-text-mini span:hover {
  background-color: #1a83ff;
}
.nav-text-mini.active {
  justify-content: flex-start;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #071358;
  width: 100%;
  height: 4vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.check-es {
}

.check-es:checked {
  background: #bada55 !important;
}
