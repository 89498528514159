.contact-form {
  margin-top: 3vh;
  margin-left: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.head-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 30%;
}
.ico-ct {
  font-size: 10vh;
  margin-top: 2.4vh;
  color: #fd8c22;
  margin-left: -1vh;
}
.head-contact h2 {
  font-size: 6vh;
  margin-left: 1vw;
  color: #071358;
}
.contact-form form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  align-items: center;
}
.contact-form form label {
  margin-top: 3vh;
  color: #071358;
  font-size: 3vh;
}
.contact-form form input {
  margin-top: 1vh;
  width: 60%;
  height: 5vh;
  border-color: #071358;
}
.contact-form form textarea {
  margin-top: 1vh;
  height: 15vh;
  width: 60%;
  border: 2px solid #071358;
}
.contact-form form button {
  margin-top: 3vh;
  width: 60%;
  color: white;
  height: 8vh;
  background-color: #fd8c22;
  border-color: #fd8c22;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 3.5vh;
}
.contact-form form button:hover {
  background-color: #071358;
  border: 3px solid #071358;
}

@media (max-width: 767px) {
  .head-contact {
    width: 80vw;
    margin-left: -10vw;
  }
  .contact-form form {
    width: 120vw;
    margin-left: -6vw;
  }
  .ico-ct {
    font-size: 6vh;
  }
  .head-contact h2 {
    font-size: 5vh;
  }
}
