.h-card-group {
  background-color: #071358;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 3px;
  margin-top: 10px;
}
.h-card-group h1 {
  text-align: center;
}
