.auth-btn-reg {
  font-size: 2vh;
  color: #071358;
  background-color: white;
  border-radius: 5px;
  width: 25vh;
  height: 4vh;
  margin-top: 10%;
  border: 2px solid #071358;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
}
.auth-btn-reg:hover {
  background-color: rgb(236, 231, 231);
}
