.mag-mag {
  margin: 0.5vw;
  display: flex;
  flex-direction: column;
  margin-left: 5.5%;
}
.single-header {
  display: flex;
  flex-direction: column;
}
.card-group {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .card-group {
    margin-left: 45px;
  }
}
