.single-mag {
  margin: 0.5vw;
  display: flex;
  flex-direction: column;
  margin-left: 4vw;
}
.head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.head-img-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -5.5vw;
}
.head-img-title img {
  height: 5.5vw;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-left: 2vw;
  margin-right: 2vw;
}
.nome-gratta {
  padding: 1.5vw;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #071358;
  color: white;
  font-size: 1vw;
  border-top: 4px solid #fd8c22;
}
.head-img-title span {
  font-size: 2vw;
  color: white;
  margin-left: 30vw;
  background-color: #071358;
  padding: 1.5vw;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 4px solid #fd8c22;
}

.head input {
  margin-right: 5vw;
  height: 3.5vw;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  border: 3px solid #fd8c22;
  text-align: center;
}
.searc-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vw;
  margin-left: -8vw;
}
.searc-bar span {
  margin-left: -4vw;
  font-size: 4vw;
  color: #fd8c22;
}
.result {
  display: flex;
  margin-top: 1vw;
  align-items: center;
}

.result-serie {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.5vw;
  margin: 5px;
  width: 30vw;
  height: 35vw;
  /* border: 1px solid #fd8c22; */
  margin-top: 1vw;
  border-radius: 10px;
  overflow-y: auto;
  padding: 5px;
}
.result-mazzetta-container {
  display: flex;
  flex-direction: column;
  width: 60vw;
  align-items: center;
}
.result-mazzetta {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.5vw;
  margin: 5px;
  height: 35vw;
  border: 3px solid #071358;
  border-left: 3px solid #071358;
  border-right: 3px solid #071358;
  margin-top: 1vw;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow-y: auto;
  padding: 5px;
  width: 98%;
}
.result-mazzetta::-webkit-scrollbar {
}
.single-result {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 3px;
  background-color: #071358;
  margin-top: 5px;
  cursor: pointer;
  width: 95%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.single-result p {
  font-size: 80%;
  padding: 2%;
  margin-left: 2%;
}
.result h3 {
  font-size: 5vw;
  color: #fd8c22;
}

.single-result:hover {
  -webkit-filter: brightness(50%);
}

.single-result-mazzetta {
  background-color: #fd8c22;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  width: 100%;
  padding: 0.5vw;
  margin: 0.5vw;
  animation: zoomInRight; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.1s;
  --animate-repeat: 2;
}

.single-result-mazzetta:hover {
  -webkit-filter: brightness(50%);
}

.single-result-mazzetta span {
  font-size: 1.1vw;
}

@media (max-width: 767px) {
  .single-mag {
    margin-left: 100px;
  }
  .result {
    display: none;
  }
  .head {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .head-img-title {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .head-img-title img {
    height: 30vw;
  }
  .nome-gratta {
    margin-top: 1.5vw;
    height: 10vw;
    width: 40vw
  }
  .nome-gratta h1{
    font-size: 400%
  }
  .head-img-title span {
    margin-top: 1.5vw;
    margin-left: 0;
    height: 15vw;
    width:20vw;
    font-size:100%
  }
  .searc-bar {
    display: none;
  }
}
